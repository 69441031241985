.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-form {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form label {
    font-weight: bold;
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .login-form input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .login-form button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #45a049;
  }
  
  .language-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .language-buttons button {
    padding: 10px;
    background-color: #008CBA;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .language-buttons button:hover {
    background-color: #007B9E;
  }